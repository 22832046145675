import { map, Observable, take } from 'rxjs';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotificationApiActions, NotificationSelectors } from './store';

export const initStoreNotificationResolver: ResolveFn<any> = (): Observable<boolean> => {
  const store = inject(Store);
  const $notificationLoaded = store.select(NotificationSelectors.selectNotificationLoaded);

  return $notificationLoaded.pipe(
    map((loaded) => {
      if (!loaded) store.dispatch(NotificationApiActions.initNotification());
      return true;
    })
  );
};
